import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AccordionSummary, Accordion, AccordionDetails } from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import { TboxState } from '../../../redux/reducers';
import { updateParameter } from '../../../redux/actions';
import { WebSocketContext } from '../../WebSocket/WebSocket';

const Components = (): any => {
  const { imei } = useParams() as any;
  const tboxes = useSelector((state:TboxState) => state.tboxes);
  const dispatch = useDispatch();
  const tbox = tboxes.find((x:any) => x.imei === imei);
  const ws = useContext(WebSocketContext);

  const changeParamsValue = (cpnIndex: number, paramIndex: number, paramValue: string) => {
    dispatch(updateParameter(imei, cpnIndex, paramIndex, paramValue));
  };

  const saveParamsValue = (cpnIndex: number, paramIndex: number) => {
    const componentName = tbox.components[cpnIndex].name;
    const paramName = tbox.components[cpnIndex].parameters[paramIndex].name;
    const paramValue = tbox.components[cpnIndex].parameters[paramIndex].value;
    ws.send(
      `send|set|${
        componentName
      }|${
        paramName
      }|${
        paramValue
      }|${
        imei}`,
    );
  };
  return (
    <div>
      {tbox.components.map((component: any, cpnIndex: number) => (
        <Accordion key={component.name}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{component.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={8}
              style={{ marginTop: '10px' }}
              alignItems="center"
              direction="row"
              justify="center"
            >
              {component.parameters ? (
                component.parameters.map((p: any, paramIndex: any) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item key={component.name + p.name} xs={6}>
                    <TextField
                      label={p.name}
                      value={p.value}
                      onChange={(event) => {
                        changeParamsValue(
                          cpnIndex,
                          paramIndex,
                          event.target.value,
                        );
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment variant="filled" position="end">
                            <IconButton
                              aria-label="Save The Field"
                              onClick={() => {
                                saveParamsValue(cpnIndex, paramIndex);
                              }}
                            >
                              <SaveIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <div>Empty Params</div>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Components;
