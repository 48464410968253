import {
  createStyles, FormControlLabel, makeStyles, Switch,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { useSelector } from 'react-redux';
import ComponentList from './Components/ComponentList';
import LogList from './Logs/LogList';
// eslint-disable-next-line no-unused-vars
import { TboxState } from '../../redux/reducers';
import { WebSocketContext } from '../WebSocket/WebSocket';
import LogDumpList from './Logs/LogDumpList';
import DBList from './DB/DBList';

const useStyles = makeStyles(() => createStyles({
  card: {
    flexGrow: 1,
  },
}));

interface ParamTypes {
  imei: string
}

const Debug = () => {
  const history = useHistory();
  const classes = useStyles();
  const [log, setLog] = useState(false);
  const tboxes = useSelector((state:TboxState) => state.tboxes);
  const { imei } = useParams<ParamTypes>();
  const [checked, setChecked] = useState <boolean>(true);
  const ws = useContext(WebSocketContext);
  useEffect(() => {
    if (tboxes.findIndex((x:any) => x.imei === imei) === -1 || ws.readyState !== 1) {
      history.push('/home');
    } else {
      ws.send(`subscribe|${imei}`);
      ws.send(`send|listAll|${imei}`);
    }
  }, []);

  const refresh = () => {
    ws.send(`send|userCpnList|${imei}`);
  };

  if (tboxes.findIndex((x:any) => x.imei === imei) !== -1) {
    return (
      <div>
        <Grid
          container
          style={{ marginTop: '10px' }}
          direction="row"
          justify="center"
          alignItems="center"
          spacing={8}
        >
          <Grid item xs={10}>
            <Card className={classes.card}>
              <CardHeader
                title={imei}
                titleTypographyProps={{ align: 'center' }}
                subheader={
                `Project : ${tboxes.find((x:any) => x.imei === imei).project}`
              }
                subheaderTypographyProps={{ align: 'center' }}
                action={(
                  <IconButton
                    onClick={() => {
                      ws.send(`send|reboot|${imei}`);
                    }}
                  >
                    <SettingsBackupRestoreIcon />
                  </IconButton>

              )}
              />
              <CardContent>
                <Grid
                  container
                  spacing={8}
                  alignItems="flex-start"
                  direction="row"
                  justify="center"
                >
                  <Grid item xs={4}>
                    <Grid
                      container
                      spacing={8}
                      alignItems="flex-start"
                      direction="row"
                      justify="center"
                    >
                      <Grid item xs={8}>
                        <Typography variant="subtitle1" align="center">
                          Components
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={refresh}
                        >
                          <SettingsBackupRestoreIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <ComponentList />
                  </Grid>
                  <Grid item xs={8}>
                    <Card>
                      <CardHeader
                        title="Logs"
                        titleTypographyProps={
                            { align: 'center' }
                        }
                        action={(
                          <div>
                            <FormControlLabel
                              control={(
                                <Switch
                                  checked={checked}
                                  onChange={(event) => setChecked(event.target.checked)}
                                  name="checkedB"
                                  color="primary"
                                />
                              )}
                              label="Autoscroll"
                            />
                            <Button
                              variant="contained"
                              onClick={() => {
                                if (log) {
                                  ws.send(`send|stop|${imei}`);
                                } else {
                                  ws.send(`send|log|${imei}`);
                                }
                                setLog((prev) => !prev);
                              }}
                            >
                              { log ? 'Stop' : 'Start'}
                            </Button>
                          </div>
                        )}
                      />
                      <CardContent>
                        <Grid
                          container
                          spacing={8}
                          alignItems="flex-start"
                          direction="row"
                          justify="center"
                        >
                          <Grid item xs={12}>
                            <LogList checked={checked} />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" align="center">
                      DB Variables
                    </Typography>
                    <DBList />
                  </Grid>
                  <Grid item xs={8}>
                    <Card>
                      <CardHeader
                        title="Log Dump"
                        titleTypographyProps={
                            { align: 'center' }
                        }
                        action={(
                          <Button
                            variant="contained"
                            onClick={() => {
                              ws.send(`send|logdump|${imei}`);
                            }}
                          >
                            Start
                          </Button>
                        )}
                      />
                      <CardContent>
                        <Grid
                          container
                          spacing={8}
                          alignItems="flex-start"
                          direction="row"
                          justify="center"
                        >
                          <Grid item xs={12}>
                            <LogDumpList />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
  return <div />;
};

export default Debug;
