import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { TboxState } from '../../redux/reducers';
import { WebSocketContext } from '../WebSocket/WebSocket';
import CustomTable from '../CustomTable/CustomTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Home = () => {
  const classes = useStyles();
  const tboxes = useSelector((state:TboxState) => state.tboxes);
  const ws = useContext(WebSocketContext);

  useEffect(() => {
    ws.addEventListener('open', () => {
      ws.send('imeiList');
    });
  }, [ws]);

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={10}>
          <CustomTable rows={tboxes} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
