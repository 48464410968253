import {
  // eslint-disable-next-line no-unused-vars
  createStyles, Tooltip, makeStyles, Theme,
} from '@material-ui/core';
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import DisconnectIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));

const CustomAppBar = () => {
  const classes = useStyles();
  const history = useHistory();

  const disconnect = () => {
    localStorage.removeItem('token');
    history.push('/');
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={() => history.push('/home')}>
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Remote Debug
          </Typography>
          <div className={classes.grow} />
          <div>
            <Tooltip title="disconnect" aria-label="Disconnect">
              <IconButton
                id="disconnect-button"
                color="inherit"
                onClick={() => disconnect()}
              >
                <DisconnectIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default CustomAppBar;
