/* eslint-disable max-len */
import stripAnsi from 'strip-ansi';
import {
  REMOVE_TBOX, NEW_TBOX, TBOX_LIST, NEW_COMPONENT, NEW_PARAMETER, NEW_LOG, UPDATE_PARAMETER, COMPONENT_LIST_END, NEW_LOG_DUMP, UPDATE_DB, NEW_DB, LIST_ALL,
} from '../actions/types';

export interface TboxState {
  tboxes: any
}

const initialState = {
  tboxes: [],
};

const convertDate = (message) => {
  if (message.startsWith('@4000000')) {
    const dateHex = message.slice(9, 17);
    const timestamp = parseInt(dateHex, 16);
    const date = new Date(timestamp * 1000);
    return `${date.toUTCString()} ${message.slice(26)}`;
  }
  return message;
};

const tboxes = (state:any, action:any) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  let tboxIndex = -1;
  let cpnIndex = -1;
  let messages = [];
  let message = '';
  switch (action.type) {
    case NEW_TBOX:
      if (state.tboxes.find((x:any) => x.imei === action.tbox.imei) === undefined) {
        const newTboxes = [
          ...state.tboxes,
          {
            ...action.tbox, components: [], logs: [], logDumps: [], dbs: [],
          },
        ].slice().sort((a, b) => {
          const nameA = a.imei.toLowerCase();
          const nameB = b.imei.toLowerCase();
          const projectA = a.project.toLowerCase();
          const projectB = b.project.toLowerCase();

          if (projectA === projectB) {
            // eslint-disable-next-line no-nested-ternary
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
          }

          return (projectA < projectB) ? -1 : 1;
        });
        return {
          ...state,
          tboxes: newTboxes,
        };
      }
      return state;
    case REMOVE_TBOX:
      tboxIndex = state.tboxes.findIndex((x:any) => x.imei === action.tbox.imei);
      if (tboxIndex !== -1) {
        return {
          ...state,
          tboxes: [
            ...state.tboxes.slice(0, tboxIndex),
            ...state.tboxes.slice(tboxIndex + 1),
          ],
        };
      }
      return state;
    case TBOX_LIST:
      return {
        ...state,
        tboxes: action.tboxList.slice().sort((a, b) => {
          const nameA = a.imei.toLowerCase();
          const nameB = b.imei.toLowerCase();
          const projectA = a.project.toLowerCase();
          const projectB = b.project.toLowerCase();

          if (projectA === projectB) {
            // eslint-disable-next-line no-nested-ternary
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
          }

          return (projectA < projectB) ? -1 : 1;
        }),
      };
    case NEW_COMPONENT:
      tboxIndex = state.tboxes.findIndex((x:any) => x.imei === action.imei);
      if (state.tboxes[tboxIndex].components.find((x:any) => x.name === action.component) === undefined) {
        return {
          ...state,
          tboxes: [
            ...state.tboxes.slice(0, tboxIndex),
            {
              ...state.tboxes[tboxIndex],
              components: [
                ...state.tboxes[tboxIndex].components,
                {
                  name: action.component,
                  parameters: [],
                },
              ],
            },
            ...state.tboxes.slice(tboxIndex + 1),
          ],
        };
      }
      return state;
    case LIST_ALL:
      tboxIndex = state.tboxes.findIndex((x:any) => x.imei === action.imei);
      return {
        ...state,
        tboxes: [
          ...state.tboxes.slice(0, tboxIndex),
          {
            ...state.tboxes[tboxIndex],
            components: action.all.components,
          },
          ...state.tboxes.slice(tboxIndex + 1),
        ],
      };
    case NEW_PARAMETER:
      tboxIndex = state.tboxes.findIndex((x:any) => x.imei === action.imei);
      cpnIndex = state.tboxes[tboxIndex].components.findIndex(
        (x:any) => x.name === action.component,
      );
      // eslint-disable-next-line no-case-declarations
      const paramIndex = state.tboxes[tboxIndex].components[cpnIndex].parameters.findIndex((x:any) => x.name === action.parameter);
      if (paramIndex === -1) {
        return {
          ...state,
          tboxes: [
            ...state.tboxes.slice(0, tboxIndex),
            {
              ...state.tboxes[tboxIndex],
              components: [
                ...state.tboxes[tboxIndex].components.slice(0, cpnIndex),
                {
                  ...state.tboxes[tboxIndex].components[cpnIndex],
                  parameters: [
                    ...state.tboxes[tboxIndex].components[cpnIndex].parameters,
                    { name: action.parameter, value: action.value },
                  ],
                },
                ...state.tboxes[tboxIndex].components.slice(cpnIndex + 1),
              ],
            },
            ...state.tboxes.slice(tboxIndex + 1),
          ],
        };
      }
      return {
        ...state,
        tboxes: [
          ...state.tboxes.slice(0, tboxIndex),
          {
            ...state.tboxes[tboxIndex],
            components: [
              ...state.tboxes[tboxIndex].components.slice(0, cpnIndex),
              {
                ...state.tboxes[tboxIndex].components[cpnIndex],
                parameters: [
                  ...state.tboxes[tboxIndex].components[cpnIndex].parameters.slice(0, paramIndex),
                  {
                    ...state.tboxes[tboxIndex].components[cpnIndex].parameters[paramIndex],
                    value: action.value,
                  },
                  ...state.tboxes[tboxIndex].components[cpnIndex].parameters.slice(paramIndex + 1),
                ],
              },
              ...state.tboxes[tboxIndex].components.slice(cpnIndex + 1),
            ],
          },
          ...state.tboxes.slice(tboxIndex + 1),
        ],
      };
    case UPDATE_PARAMETER:
      tboxIndex = state.tboxes.findIndex((x:any) => x.imei === action.imei);
      return {
        ...state,
        tboxes: [
          ...state.tboxes.slice(0, tboxIndex),
          {
            ...state.tboxes[tboxIndex],
            components: [
              ...state.tboxes[tboxIndex].components.slice(0, action.componentIndex),
              {
                ...state.tboxes[tboxIndex].components[action.componentIndex],
                parameters: [
                  ...state.tboxes[tboxIndex].components[action.componentIndex].parameters.slice(0, action.parameterIndex),
                  {
                    ...state.tboxes[tboxIndex].components[action.componentIndex].parameters[action.parameterIndex],
                    value: action.value,
                  },
                  ...state.tboxes[tboxIndex].components[action.componentIndex].parameters.slice(action.parameterIndex + 1),
                ],
              },
              ...state.tboxes[tboxIndex].components.slice(action.componentIndex + 1),
            ],
          },
          ...state.tboxes.slice(tboxIndex + 1),
        ],
      };
    case NEW_DB:
      tboxIndex = state.tboxes.findIndex((x:any) => x.imei === action.imei);
      // eslint-disable-next-line no-case-declarations
      const dbIndex = state.tboxes[tboxIndex].dbs.findIndex((x:any) => x.name === action.db);
      if (dbIndex !== -1) {
        return {
          ...state,
          tboxes: [
            ...state.tboxes.slice(0, tboxIndex),
            {
              ...state.tboxes[tboxIndex],
              dbs: [
                ...state.tboxes[tboxIndex].dbs.slice(0, dbIndex),
                {
                  ...state.tboxes[tboxIndex].dbs[dbIndex],
                  value: action.value,
                },
                ...state.tboxes[tboxIndex].dbs.slice(dbIndex + 1),
              ],
            },
            ...state.tboxes.slice(tboxIndex + 1),
          ],
        };
      }
      return {
        ...state,
        tboxes: [
          ...state.tboxes.slice(0, tboxIndex),
          {
            ...state.tboxes[tboxIndex],
            dbs: [
              ...state.tboxes[tboxIndex].dbs,
              {
                name: action.db,
                value: action.value,
              },
            ],
          },
          ...state.tboxes.slice(tboxIndex + 1),
        ],
      };
    case UPDATE_DB:
      tboxIndex = state.tboxes.findIndex((x:any) => x.imei === action.imei);
      return {
        ...state,
        tboxes: [
          ...state.tboxes.slice(0, tboxIndex),
          {
            ...state.tboxes[tboxIndex],
            dbs: [
              ...state.tboxes[tboxIndex].dbs.slice(0, action.dbIndex),
              {
                ...state.tboxes[tboxIndex].dbs[action.dbIndex],
                value: action.value,
              },
              ...state.tboxes[tboxIndex].dbs.slice(action.dbIndex + 1),
            ],
          },
          ...state.tboxes.slice(tboxIndex + 1),
        ],
      };
    case NEW_LOG:
      tboxIndex = state.tboxes.findIndex((x:any) => x.imei === action.imei);
      messages = action.message.map((element) => convertDate(stripAnsi(element)));
      return {
        ...state,
        tboxes: [
          ...state.tboxes.slice(0, tboxIndex),
          {
            ...state.tboxes[tboxIndex],
            logs: [
              ...state.tboxes[tboxIndex].logs,
              ...messages,
            ],
          },
          ...state.tboxes.slice(tboxIndex + 1),
        ],
      };
    case NEW_LOG_DUMP:
      tboxIndex = state.tboxes.findIndex((x:any) => x.imei === action.imei);
      message = convertDate(stripAnsi(action.message));
      return {
        ...state,
        tboxes: [
          ...state.tboxes.slice(0, tboxIndex),
          {
            ...state.tboxes[tboxIndex],
            logDumps: [
              ...state.tboxes[tboxIndex].logDumps,
              message,
            ],
          },
          ...state.tboxes.slice(tboxIndex + 1),
        ],
      };
    case COMPONENT_LIST_END:
      tboxIndex = state.tboxes.findIndex((x:any) => x.imei === action.imei);
      state.tboxes[tboxIndex].components.map((cpn:any) => action.ws.send(`send|list|${cpn.name}|${action.imei}`));
      return state;
    default:
      return state;
  }
};

export default tboxes;
