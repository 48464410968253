export const NEW_TBOX = 'NEW_TBOX';
export const REMOVE_TBOX = 'REMOVE_TBOX';
export const TBOX_LIST = 'TBOX_LIST';
export const NEW_LOG = 'NEW_LOG';
export const NEW_COMPONENT = 'NEW_COMPONENT';
export const NEW_PARAMETER = 'NEW_PARAMETER';
export const LIST_ALL = 'LIST_ALL';
export const UPDATE_PARAMETER = 'UPDATE_PARAMETER';
export const NEW_DB = 'NEW_DB';
export const UPDATE_DB = 'UPDATE_DB';
export const COMPONENT_LIST_END = 'COMPONENT_LIST_END';
export const NEW_LOG_DUMP = 'NEW_LOG_DUMP';

interface NewTboxAction {
  type: typeof NEW_TBOX
  tbox: any
}
interface RemoveTboxAction {
  type: typeof REMOVE_TBOX
  tbox: any
}
interface TboxListAction {
  type: typeof TBOX_LIST
  tboxList: any
}

interface NewLogAction {
  type: typeof NEW_LOG
  message: string,
  imei: string,
}

interface NewComponentAction {
  type: typeof NEW_COMPONENT
  imei: string,
  component: string,
  isStarted: string,
}

interface NewParameterAction {
  type: typeof NEW_PARAMETER
  imei: string,
  component: string,
  parameter: string,
  value: string,
}

interface ListAllAction {
  type: typeof LIST_ALL
  imei: string,
  all: any,
}

interface UpdateParameterAction {
  type: typeof UPDATE_PARAMETER
  imei: string,
  componentIndex: string,
  parameterIndex: string,
  value: string,
}

interface ComponentListEndAction {
  type: typeof COMPONENT_LIST_END
  imei: string,
}

interface NewLogDumpAction {
  type: typeof NEW_LOG_DUMP
  message: string,
  imei: string,
}

interface NewDB {
  type: typeof NEW_DB
  imei: string,
  db: string,
  value: string,
}

interface UpdateDB {
  type: typeof UPDATE_DB
  imei: string,
  dbIndex: string,
  value: string,
}
// eslint-disable-next-line max-len
export type ActionTypes = NewDB|UpdateDB|NewTboxAction|TboxListAction|NewLogAction|NewComponentAction|NewParameterAction|UpdateParameterAction|ComponentListEndAction|NewLogDumpAction
