import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import CustomAppBar from '../CustomAppBar/CustomAppBar';
import Home from '../Home/Home';
import Debug from '../Debug/Debug';

const Menu = () => {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token === null) {
      history.push('/');
    }
  }, [history]);

  return (
    <div>
      <CssBaseline />
      <CustomAppBar />
      <main>
        <Switch>
          <Route
            exact
            path="/home"
            component={() => (
              <Home />
            )}
          />
          <Route
            path="/debug/:imei"
            component={() => (
              <Debug />
            )}
          />
        </Switch>
      </main>
    </div>
  );
};

export default Menu;
