import React, { createContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import {
  newTbox,
  tboxList,
  newLog,
  newComponent,
  newParameter,
  componentListEnd,
  newLogDump,
  newDB,
  removeTbox,
  listAll,
} from '../../redux/actions';

const WebSocketContext = createContext<any>(null);

export { WebSocketContext };

const WebSocketComponent = (props:any) => {
  let socket:any;
  const history = useHistory();
  const { children } = props;

  const dispatch = useDispatch();

  if (!socket) {
    socket = new WebSocket(
      `${config.webSocketUrl}?token=${
        localStorage.getItem('token')}`,
    );
    socket.onmessage = (evt:any) => {
      if (evt.data.startsWith('$newImei|')) {
        dispatch(newTbox(evt));
      } else if (evt.data.startsWith('$removeImei|')) {
        dispatch(removeTbox(evt));
      } else if (evt.data.startsWith('$imeiList|')) {
        dispatch(tboxList(evt));
      } else if (evt.data.startsWith('$log|')) {
        if (!evt.data.startsWith('$log|[]')) { dispatch(newLog(evt)); }
      } else if (evt.data.startsWith('$logdump|')) {
        if (!evt.data.startsWith('$logDump|[]')) { dispatch(newLogDump(evt)); }
      } else if (evt.data.startsWith('$userCpnList|')) {
        const splitMessage = evt.data.split('|');
        const cpn = splitMessage[1];
        if (cpn.includes('michelin')) {
          dispatch(newComponent(evt));
        }
      } else if (evt.data.startsWith('$list|')) {
        dispatch(newParameter(evt));
      } else if (evt.data.startsWith('$listAll|')) {
        dispatch(listAll(evt));
      } else if (evt.data.startsWith('$userCpnListEnd|')) {
        dispatch(componentListEnd(socket, evt));
      } else if (evt.data.startsWith('$getDB|')) {
        const splitMessage = evt.data.split('|');
        const imei = splitMessage[3];
        const db = splitMessage[1];
        const value = splitMessage[2];
        dispatch(newDB(imei, db, value));
      }
    };
    socket.onerror = (evt:any) => {
      console.error(evt);
      localStorage.removeItem('token');
      history.push('/');
    };
  }

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketComponent;
