import React, { useState, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CustomSnackbar from '../CustomSnackbar/CustomSnackbar';
import config from '../../config';

const useStyles = makeStyles((theme: Theme) => createStyles({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${
      theme.spacing(3)
    }px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

const Login = () => {
  const history = useHistory();
  const classes = useStyles();
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorLogin, setErrorLogin] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState<string>('');

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      history.push('/home');
    }
  });

  const handleSignIn = async () => {
    if (login !== '' && password !== '') {
      try {
        const authResponse = await axios({
          method: 'post',
          url: `${config.httpsUrl}/authenticate`,
          data: {
            name: login,
            password,
          },
        });
        if (authResponse.data.success) {
          localStorage.setItem('token', authResponse.data.token);
          history.push('/home');
        } else {
          setErrorLogin('wrong');
          setErrorPassword('wrong');
        }
      } catch (error) {
        if (error.message.includes('Please, enter valid credentials')) {
          setErrorLogin('wrong');
          setErrorPassword('wrong');
        } else {
          console.error(error.message);
          setErrorLogin('issue');
          setErrorPassword('issue');
        }
      }
    } else {
      if (login === '') {
        setErrorLogin('required');
      } else {
        setErrorLogin('');
      }
      if (password === '') {
        setErrorPassword('required');
      } else {
        setErrorPassword('');
      }
    }
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Remote Debug
        </Typography>
        <form className={classes.form}>
          <FormControl
            id="login-control"
            margin="normal"
            error={errorLogin !== ''}
            fullWidth
          >
            <InputLabel htmlFor="login">Login</InputLabel>
            <Input
              id="login"
              name="login"
              autoComplete="login"
              value={login}
              onChange={(event) => setLogin(event.target.value)}
              autoFocus
            />
            <FormHelperText id="component-error-text">
              {errorLogin === 'required'
                ? 'Please fill the login'
                : 'Required'}
            </FormHelperText>
          </FormControl>
          <FormControl
            id="password-control"
            margin="normal"
            error={errorPassword !== ''}
            fullWidth
          >
            <InputLabel htmlFor="password">Passwork</InputLabel>
            <Input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              autoComplete="current-password"
            />
            <FormHelperText id="component-error-text">
              {errorPassword === 'required'
                ? 'Please fill the password'
                : 'Required'}
            </FormHelperText>
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          {errorPassword === 'wrong' ? (
            <CustomSnackbar
              id="error-snackbar"
              variant="error"
              message="Wrong Credentials"
              className=""
              onClose={() => {}}
            />
          ) : null}
          {errorPassword === 'issue' ? (
            <CustomSnackbar
              id="error-snackbar"
              variant="error"
              message="Issue with authentification"
              className=""
              onClose={() => {}}
            />
          ) : null}
          <Button
            id="signin"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => handleSignIn()}
          >
            Log In
          </Button>
        </form>
      </Paper>
    </main>
  );
};

export default Login;
