/* eslint-disable no-unused-vars */
import Grid from '@material-ui/core/Grid';
import React, {
  useState,
  useRef,
} from 'react';
import { List, CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { makeStyles, createStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TboxState } from '../../../redux/reducers';

const useStyles = makeStyles(() => createStyles({
  card: {
    flexGrow: 1,
  },
  paper: {
    padding: 5,
    height: 400,
    backgroundColor: blueGrey[800],
    color: blueGrey[50],
  },
}));

interface ParamTypes {
  imei: string
}

const LogDumpList = (): any => {
  const classes = useStyles();
  const [autoScroll, setAutoScroll] = useState(true);
  const { imei } = useParams<ParamTypes>();
  const tboxes = useSelector((state:TboxState) => state.tboxes);
  const { logDumps } = tboxes.find((x:any) => x.imei === imei);
  const divRef = useRef<any>(null);
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 20,
  });

  const rowRenderer = ({
    index, parent, key, style,
  }) => (
    <CellMeasurer
      key={key}
      cache={cache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      <div style={style}>
        {logDumps[index]}
      </div>
    </CellMeasurer>
  );

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      setAutoScroll(!autoScroll);
    }
  };

  return (
    <div className={classes.paper}>
      <Grid container spacing={0} onKeyPress={handleKeyPress}>
        <Grid item xs={12} md={12}>
          <div style={{ width: '100%' }} ref={divRef}>
            {divRef.current === null ? null : (
              <List
                width={divRef.current.getBoundingClientRect().width}
                height={390}
                rowCount={logDumps.length}
                overscanRowCount={0}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                scrollToIndex={autoScroll ? logDumps.length - 1 : undefined}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LogDumpList;
