import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  // eslint-disable-next-line no-unused-vars
  createStyles, Theme, withStyles, WithStyles,
  makeStyles,
} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import {
  // eslint-disable-next-line no-unused-vars
  AutoSizer, Column, Table, TableCellRenderer, TableHeaderProps,
} from 'react-virtualized';
import ForwardIcon from '@material-ui/icons/Forward';
import { useHistory } from 'react-router-dom';
import { TextField, Tooltip, Typography } from '@material-ui/core';

declare module '@material-ui/core/styles/withStyles' {
  // Augment the BaseCSSProperties so that we can control jss-rtl
  interface BaseCSSProperties {
    /*
     * Used to control if the rule-set should be affected by rtl transformation
     */
    flip?: boolean;
  }
}

const styles = (theme: Theme) => createStyles({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
});

interface ColumnData {
  dataKey: string;
  label: string;
  numeric?: boolean;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: ColumnData[];
  headerHeight?: number;
  onRowClick: () => void;
  rowCount: number;
  rowGetter: (row: Row) => Data;
  rowHeight?: number;
}
const MuiVirtualizedTable = (props:MuiVirtualizedTableProps) => {
  const {
    classes, columns, rowHeight, headerHeight, onRowClick, ...tableProps
  } = props;
  const history = useHistory();

  const getRowClassName = ({ index }: Row) => clsx(classes.tableRow, classes.flexContainer, {
    [classes.tableRowHover]: index !== -1 && onRowClick != null,
  });

  const cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => (
    <TableCell
      component="div"
      className={clsx(classes.tableCell, classes.flexContainer, {
        [classes.noClick]: onRowClick == null,
      })}
      variant="body"
      style={{ height: rowHeight }}
      align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
    >
      <Tooltip title={cellData || ''} placement="top">
        <Typography>
          {cellData}
        </Typography>
      </Tooltip>
    </TableCell>
  );

  const buttonCellRenderer: TableCellRenderer = ({ cellData }) => (
    <TableCell
      align="center"
      component="div"
      className={clsx(classes.tableCell, classes.flexContainer, {
        [classes.noClick]: onRowClick == null,
      })}
      variant="body"
      style={{ height: rowHeight }}
    >
      <IconButton aria-label="goto" onClick={() => history.push(`/debug/${cellData}`)}>
        <ForwardIcon />
      </IconButton>
    </TableCell>
  );

  const headerRenderer = ({ label, columnIndex }: TableHeaderProps & { columnIndex: number }) => (
    <TableCell
      component="div"
      className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
      variant="head"
      style={{ height: headerHeight }}
      align={columns[columnIndex].numeric || false ? 'right' : 'left'}
    >
      <span>{label}</span>
    </TableCell>
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          height={height}
          width={width}
          rowHeight={rowHeight!}
          gridStyle={{
            direction: 'inherit',
          }}
          headerHeight={headerHeight!}
          className={classes.table}
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...tableProps}
          rowClassName={getRowClassName}
        >
          {columns.map(({ dataKey, label }, index) => (
            <Column
              key={dataKey}
              headerRenderer={(headerProps) => headerRenderer({
                ...headerProps,
                columnIndex: index,
              })}
              className={classes.flexContainer}
              cellRenderer={cellRenderer}
              dataKey={dataKey}
              width={width}
              label={label}
            />
          ))}

          <Column
            key="detail"
            headerRenderer={(headerProps) => headerRenderer({
              ...headerProps,
              columnIndex: 0,
            })}
            className={classes.flexContainer}
            cellRenderer={buttonCellRenderer}
            dataKey="detail"
            width={width}
            label="Detail"
            cellDataGetter={(datas) => datas.rowData.imei}
          />
        </Table>
      )}
    </AutoSizer>
  );
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 48,
  rowHeight: 48,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

// ---

interface Data {
  imei: string;
  project: string;
  configuration: string;
  imsi: string;
  iccid: string;
  phoneNumber: string;
}

type PropsType = {
    rows: any[],
}

const useStyles = makeStyles(() => createStyles({
  title: {
    flex: '1 1 100%',
  },
}));

function usePrevious(value:any) :any {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  if (ref.current !== undefined) {
    return ref.current;
  }
  return {};
}

export default function ReactVirtualizedTable(props:PropsType) {
  const { rows } = props;
  const classes = useStyles();
  const [search, setSearch] = useState<string>('');
  const [granted, setGranted] = useState<boolean>(false);
  const prevRows = usePrevious({ rows });
  const filterFunction = (r) => r.imei.toLowerCase().includes(search.toLowerCase())
  || r.project.toLowerCase().includes(search.toLowerCase())
  || r.model.toLowerCase().includes(search.toLowerCase())
  || r.configuration.toLowerCase().includes(search.toLowerCase());
  const filteredDatas = rows.filter(filterFunction);

  useEffect(() => {
    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        setGranted(true);
      } else {
        setGranted(false);
      }
    });
    if (granted && search.length > 0) {
      if (prevRows !== undefined) {
        if (prevRows.rows.length === (rows.length - 1)) {
          const difference = filteredDatas.filter(
            (obj) => !prevRows.rows.some((obj2) => obj.imei === obj2.imei),
          );
          if (difference.length > 0) {
            const img = 'icon-128.png';
            const { imei } = difference[0];
            const text = `The Telematic Box : ${imei} just appeard`;
            // eslint-disable-next-line no-new
            new Notification('New TB', { body: text, icon: img });
          }
        }
      }
    }
  }, [rows]);
  return (
    <div>
      <Paper>
        <Toolbar>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Available Telematic Boxes
          </Typography>
          <TextField id="outlined-basic" label="Search" variant="outlined" value={search} onChange={(event) => setSearch(event.target.value)} />
        </Toolbar>
      </Paper>
      <Paper style={{ height: '80vh', width: '100%' }}>
        <VirtualizedTable
          rowCount={filteredDatas.length}
          onRowClick={() => {}}
          rowGetter={({ index }) => filteredDatas[index]}
          columns={[
            {
              label: 'IMEI',
              dataKey: 'imei',
            },
            {
              label: 'Model',
              dataKey: 'model',
            },
            {
              label: 'Project',
              dataKey: 'project',
            },
            {
              label: 'Configuration',
              dataKey: 'configuration',
            },
            {
              label: 'IMSI',
              dataKey: 'imsi',
            },
            {
              label: 'ICCID',
              dataKey: 'iccid',
            },
            {
              label: 'Phone Number',
              dataKey: 'phoneNumber',
            },
          ]}
        />
      </Paper>
    </div>

  );
}
