import {
  REMOVE_TBOX,
  NEW_TBOX,
  TBOX_LIST,
  NEW_LOG,
  NEW_COMPONENT,
  NEW_PARAMETER,
  LIST_ALL,
  COMPONENT_LIST_END,
  UPDATE_PARAMETER,
  NEW_LOG_DUMP,
  NEW_DB,
  UPDATE_DB,
} from './types';

export const newTbox = (evt:any) => ({
  type: NEW_TBOX,
  tbox: JSON.parse(evt.data.split('|')[1]),
});

export const removeTbox = (evt:any) => ({
  type: REMOVE_TBOX,
  tbox: JSON.parse(evt.data.split('|')[1]),
});

export const tboxList = (evt:any) => ({
  type: TBOX_LIST,
  tboxList: JSON.parse(evt.data.split('|')[1]).map((tb: any) => ({
    ...tb,
    components: [],
    logs: [],
    logDumps: [],
    dbs: [],
  })),
});

export const newLog = (evt:any) => {
  const splitMessage = evt.data.split('|');
  return {
    type: NEW_LOG,
    message: JSON.parse(splitMessage[1]),
    imei: splitMessage[splitMessage.length - 1],
  };
};

export const newLogDump = (evt:any) => {
  const splitMessage = evt.data.split('|');
  return {
    type: NEW_LOG_DUMP,
    message: splitMessage[1],
    imei: splitMessage[splitMessage.length - 1],
  };
};

export const newComponent = (evt:any) => {
  const splitMessage = evt.data.split('|');
  const component = splitMessage[1];
  const isStarted = splitMessage[2];
  return {
    type: NEW_COMPONENT,
    imei: splitMessage[splitMessage.length - 1],
    component,
    isStarted,
  };
};

export const newParameter = (evt:any) => {
  const splitMessage = evt.data.split('|');
  const component = splitMessage[1];
  const parameter = splitMessage[2];
  const value = splitMessage[3];
  return {
    type: NEW_PARAMETER,
    imei: splitMessage[splitMessage.length - 1],
    component,
    parameter,
    value,
  };
};

export const listAll = (evt:any) => {
  const imei = evt.data.slice(-15);
  const message = evt.data.slice(9, -16);
  return {
    type: LIST_ALL,
    imei,
    all: JSON.parse(message),
  };
};

export const updateParameter = (
  imei:string,
  componentIndex:number,
  parameterIndex:number,
  value:string,
) => ({
  type: UPDATE_PARAMETER,
  imei,
  componentIndex,
  parameterIndex,
  value,
});

export const componentListEnd = (ws:any, evt:any) => {
  const splitMessage = evt.data.split('|');
  return {
    type: COMPONENT_LIST_END,
    imei: splitMessage[splitMessage.length - 1],
    ws,
  };
};

export const newDB = (imei:string, db:string, value:string) => ({
  type: NEW_DB,
  imei,
  db,
  value,
});

export const updateDB = (imei:string, dbIndex:number, value:string) => ({
  type: UPDATE_DB,
  imei,
  dbIndex,
  value,
});
