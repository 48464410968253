import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import Login from './components/Login/Login';
import Menu from './components/Menu/Menu';
import store from './redux/store';
import WebSocketComponent from './components/WebSocket/WebSocket';

const theme = createMuiTheme();

const App = () => (
  <Router>
    <Provider store={store}>
      <WebSocketComponent>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Route path="/" exact component={Login} />
            <Route component={Menu} />
          </Switch>
        </MuiThemeProvider>
      </WebSocketComponent>
    </Provider>
  </Router>
);

render(<App />, document.getElementById('root'));
