import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@material-ui/icons/Save';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { TboxState } from '../../../redux/reducers';
import { updateDB } from '../../../redux/actions';
import { WebSocketContext } from '../../WebSocket/WebSocket';

const DBList = (): any => {
  const { imei } = useParams() as any;
  const history = useHistory();
  const tboxes = useSelector((state:TboxState) => state.tboxes);
  const dispatch = useDispatch();
  const tbox = tboxes.find((x:any) => x.imei === imei);
  const ws = useContext(WebSocketContext);

  useEffect(() => {
    if (tboxes.findIndex((x:any) => x.imei === imei) === -1 || ws.readyState !== 1) {
      history.push('/home');
    } else {
      ws.send(`subscribe|${imei}`);
      ws.send(`send|getDB|MDI_DIO_IGNITION|${imei}`);
      ws.send(`send|getDB|IGNITION_ON|${imei}`);
    }
  }, []);
  const changeDBValue = (dbIndex: number, dbValue: string) => {
    dispatch(updateDB(imei, dbIndex, dbValue));
  };

  const saveDBValue = (dbIndex: number) => {
    const dbName = tbox.dbs[dbIndex].name;
    const dbValue = tbox.dbs[dbIndex].value;
    ws.send(
      `send|setDB|${
        dbName
      }|${
        dbValue
      }|${
        imei}`,
    );
  };
  return (
    <Grid
      container
      spacing={8}
      alignItems="flex-start"
      direction="row"
      justify="center"
    >
      {tbox.dbs.map((db: any, dbIndex: number) => (
        <Grid item key={db.name} xs={8}>
          <TextField
            label={db.name}
            value={db.value}
            onChange={(event) => {
              changeDBValue(
                dbIndex,
                event.target.value,
              );
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={db.name === 'MDI_DIO_IGNITION'}
            margin="normal"
            fullWidth
            InputProps={{
              endAdornment: !(db.name === 'MDI_DIO_IGNITION') && (
                <InputAdornment variant="filled" position="end">
                  <IconButton
                    aria-label="Save The Field"
                    onClick={() => {
                      saveDBValue(dbIndex);
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DBList;
